<template>
  <node-view-wrapper>
    <UserDetailsQuestion :question="question" :editable="isEditable" />
  </node-view-wrapper>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'

import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
import { UserDetailsQuestionConfig } from '@/types'
import UserDetailsQuestion from './UserDetailsQuestion.vue'

const components = {
  NodeViewWrapper,
  UserDetailsQuestion,
}

// eslint-disable-next-line no-use-before-define
@Component<UserDetailsQuestionTipTapView>({ components })
export default class UserDetailsQuestionTipTapView extends Vue.extend({
  props: nodeViewProps,
}) {
  get questions() {
    return this.$typedStore.activeVisualisation.visualisationStore.app.config.userDetails.questions
  }

  get questionId(): string | undefined {
    return this.node.attrs.questionId
  }

  get question(): UserDetailsQuestionConfig | undefined {
    if (this.questionId) {
      return this.questions.find((x) => x.id === this.questionId)
    }
  }

  get isEditable() {
    return this.editor?.isEditable ?? false
  }
}
</script>
