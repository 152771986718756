import {
  getAppById,
  getAppByRank,
  getAppRelationships,
  getConfigByAppId,
  getConfigByAppRank,
  getOrgAppsByOrgId,
  getVersionStatus,
} from '@/api'
import { APP_CONFIG_KEY, createFeedbackConfig } from '@/constants'
import { replaceItemAtIndex } from '@/helpers'
import logger from '@/plugins/logging/logger'
import { configService } from '@/services'
import store from '@/store'
import App, { IAppState } from '@/store/base/app'
import Images from '@/store/base/entities/images'
import Nodes from '@/store/base/entities/nodes'
import { useFeaturesStore } from '@/store/common/features'
import { addDefaultConfig, getLocalStorage, getObjectProp } from '@/store/helpers'
import { useFeedbackStore } from '@/store/public/feedback'
import { useUserDetailsStore } from '@/store/public/userDetails'
import typedStore from '@/store/typedStore'
import {
  AppRank,
  AppRanks,
  AppResponse,
  AppStatuses,
  AppTypes,
  FeedbackQuestionConfig,
  HaloBubblesConfig,
  UserDetailsQuestionConfig,
  VersionConfig,
} from '@/types'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import {
  bootstrap as gtagBootstrap,
  DomainConfig,
  optIn as gtagOptIn,
  optOut as gtagOptOut,
  pageview,
  setOptions as gtagSetOptions,
} from 'vue-gtag'
// @ts-ignore vuex-map-fields import
import { getField, updateField } from 'vuex-map-fields'
import { Action, getModule, Module, Mutation } from 'vuex-module-decorators'

interface IPrimaryAppState extends IAppState {
  orgApps: AppResponse[]
  secondaryApps: Record<number, AppResponse>
  secondaryAppsConfig: Record<number, VersionConfig>
}

export interface IPrimaryAppModuleState {
  appState: IPrimaryAppState
}

@Module({ dynamic: true, store, name: 'primaryApp', namespaced: true })
export default class PrimaryApp extends App implements IPrimaryAppModuleState {
  appState: IPrimaryAppState = this.initialState()

  get initialState(): () => IPrimaryAppState {
    return () => ({
      ...this.initialBaseState,
      orgApps: [] as AppResponse[],
      secondaryApps: {},
      secondaryAppsConfig: {},
    })
  }

  get appRank(): AppRank {
    return AppRanks.PRIMARY
  }

  get appId(): number | null {
    return typedStore.currentRoute.primaryAppId
  }

  get appSlug(): string | null {
    return typedStore.currentRoute.primaryAppSlug
  }

  get versionId(): number | null {
    return typedStore.currentRoute.primaryVersionId
  }

  get nodes(): Nodes {
    return typedStore.primary.entities.nodes
  }

  get images(): Images {
    return typedStore.primary.entities.images
  }

  get allOrganisationSecondaryApps() {
    return this.appState.orgApps.filter((app) => app.appType === AppTypes.BUBBLES)
  }

  get secondaryAppLabel(): (app: AppResponse) => string {
    return (app: AppResponse) => {
      const appStatus = Object.keys(AppStatuses).find(
        (key) => AppStatuses[key as keyof typeof AppStatuses] === app.status,
      )
      return `${app.name} (${appStatus})`
    }
  }

  get secondaryApp(): (appId: number) => AppResponse | undefined {
    return (appId: number) => this.appState.secondaryApps[appId]
  }

  get secondaryAppConfig(): (appId: number) => VersionConfig | undefined {
    return (appId: number) => this.appState.secondaryAppsConfig[appId]
  }

  get isCurrentPublishedVersion() {
    return (
      (this.app.status === AppStatuses.INTERNAL || this.app.status === AppStatuses.LIVE) &&
      (!typedStore.currentRoute.primaryVersionId ||
        typedStore.currentRoute.primaryVersionId === this.app.currentVersionId)
    )
  }

  @Action({ rawError: true })
  async initialise() {
    try {
      // Always reset app load failed
      this.setAppLoadFailed(false)

      // Check whether the app is already loaded and with the correct version
      const isLoaded = this.appState.appLoaded && this.appState.loadedVersion === this.versionId
      if (!isLoaded) {
        this.setAppLoaded(false)

        await this.getAllData()

        // Check story path is valid; else redirect to initial tab (first visible tab)
        const storyPath = typedStore.currentRoute.params.storyPath as unknown as
          | string[]
          | string
          | undefined
        const secondaryStoryPath = typedStore.currentRoute.params.secondaryStoryPath as unknown as
          | string[]
          | string
          | undefined
        const fullStoryPath = Array.isArray(storyPath) ? storyPath.join('/') : storyPath
        const topLevelSecondaryStoryPath = Array.isArray(secondaryStoryPath)
          ? secondaryStoryPath[0]
          : secondaryStoryPath?.split('/')[0]

        if (this.isVisualisationApp) {
          await typedStore.visualisationData.getTreeData()
        }
        const validPath = this.isValidPath(
          fullStoryPath,
          topLevelSecondaryStoryPath,
          this.isVisualisationApp,
        )

        if (!validPath) {
          typedStore.public.display.activateInitialTab(false)
        }

        useFeaturesStore().refreshFeatureValues()
        await useFeedbackStore().populateExistingFeedback(this.appRank, this.app.id)
        this.setAppLoaded(true)
        this.setLoadedVersion(this.versionId!)
        this.enableCustomerAnalytics()
        useUserDetailsStore().showModal()
        typedStore.public.display.showSplashScreen()
      }
    } catch {
      this.setAppLoadFailed(true)
    }
  }

  get isValidPath() {
    return (
      storyPath: string | undefined,
      secondaryStoryPath: string | undefined,
      includeTreeData: boolean,
    ) => {
      let tabFound: Boolean
      if (secondaryStoryPath) {
        // We have a secondaryStoryPath so storyPath references the secondary app as the secondaryAppPath
        tabFound = Boolean(
          storyPath &&
            typedStore.primary.entities.tabs.searchTabsByPath(secondaryStoryPath, storyPath),
        )
      } else {
        tabFound = Boolean(
          storyPath && typedStore.primary.entities.tabs.searchTabsByPath(storyPath, undefined),
        )
      }

      if (includeTreeData) {
        return (
          tabFound || Boolean(storyPath && typedStore.visualisationData.treeRoot.search(storyPath))
        )
      }

      return tabFound
    }
  }

  @Action({ rawError: true })
  async getAllData() {
    getLocalStorage()
    await this.getApp()
    await this.getVersionStatus()

    await Promise.all([
      this.getConfig(),
      this.images.getAllImages(),
      this.nodes.getNodes(),
      this.getAppRelationships(),
    ])

    await this.getOrgApps()
    await this.getSecondaryAppsData()
  }

  @Action({ rawError: true })
  async getSecondaryAppsData() {
    await this.getSecondaryApps()
    await this.getConfigForAccessibleSecondaryApps()
    await typedStore.primary.entities.nodes.getTabNodesForAccessibleSecondaryApps()
    await typedStore.primary.entities.images.getIconsForAccessibleSecondaryApps()
  }

  @Action({ rawError: true })
  async getSecondaryApps() {
    await Promise.all(
      typedStore.primary.entities.nodes.secondaryAppReferenceNodes.map((node) => {
        if (node.config.secondaryAppId) {
          return this.getSecondaryApp(node.config.secondaryAppId)
        }
        return Promise.resolve()
      }),
    )
  }

  @Action({ rawError: true })
  async getSecondaryApp(appId: number) {
    const secondaryApp = this.appState.secondaryApps[appId]
    if (!secondaryApp) {
      try {
        const app = await getAppById({ appId }, AppRanks.SECONDARY)
        this.updateSecondaryApps({ appId, app })
      } catch {
        // TODO: Actual error handling
        logger.error(`error retrieving secondary app for app: ${appId}`)
      }
    }
  }

  @Mutation
  updateSecondaryApps(payload: { appId: number; app: AppResponse }) {
    Vue.set(this.appState.secondaryApps, payload.appId, payload.app)
  }

  @Action({ rawError: true })
  async getConfigForAccessibleSecondaryApps() {
    await Promise.all(
      typedStore.primary.entities.nodes.accessibleSecondaryAppReferenceNodes.map((node) => {
        if (node.config.secondaryAppId) {
          return this.getSecondaryAppConfig(node.config.secondaryAppId)
        }
        return Promise.resolve()
      }),
    )
  }

  @Action({ rawError: true })
  async getSecondaryAppConfig(appId: number) {
    const secondaryAppConfig = this.appState.secondaryAppsConfig[appId]
    if (!secondaryAppConfig) {
      try {
        const configResponse = await getConfigByAppId({ appId }, AppRanks.SECONDARY)
        const config = await addDefaultConfig(this.appState.app, configResponse)
        this.updateSecondaryAppsConfig({ appId, config })
      } catch {
        // TODO: Actual error handling
        logger.error(`error retrieving secondary app config for app: ${appId}`)
      }
    }
  }

  @Mutation
  updateSecondaryAppsConfig(payload: { appId: number; config: VersionConfig }) {
    Vue.set(this.appState.secondaryAppsConfig, payload.appId, payload.config)
  }

  @Mutation
  setOrgApps(payload: AppResponse[]) {
    this.appState.orgApps = payload
  }

  @Action({ rawError: true })
  async getOrgApps() {
    if (typedStore.admin.userPermission.builder) {
      this.setOrgApps(await getOrgAppsByOrgId(this.appState.app.orgId))
    }
  }

  @Action({ rawError: true })
  async getApp() {
    const app = await getAppByRank(this.appRank)
    if (!app) throw new Error('Primary app not found')
    this.setApp(app)
  }

  @Action({ rawError: true })
  async getVersionStatus() {
    const status = await getVersionStatus(this.appRank)
    this.setAppLoadFailed(status === 0)
  }

  // config
  @Mutation
  setObjectProp(payload: { configPath: (string | number)[]; object: any; value: unknown }) {
    const { configPath, object, value } = payload
    const key = configPath.slice(-1)[0]
    if (object) {
      // Use Vue.set to ensure reactivity added to new prop
      Vue.set(object, key, value)
    } else {
      console.error('Invalid config path', configPath, object, value)
    }
  }

  @Action({ rawError: true })
  async getConfig() {
    const config = await getConfigByAppRank(this.appRank)
    this.setConfig(await addDefaultConfig(this.appState.app, config))
  }

  @Action({ rawError: true })
  async getAppRelationships() {
    if (this.appId) {
      const relationships = await getAppRelationships({ appId: this.appId })
      this.setRelationships(relationships)
    }
  }

  @Mutation
  updateFieldConfig(payload: { column: string; partialValue: object }) {
    const { column, partialValue } = payload
    const fieldMap = this.appState.config.fieldMap
    const ix = fieldMap.findIndex((f) => f.column === column)
    if (ix < 0) {
      console.warn(`Could not update field for unknown column: ${column}`)
    } else {
      Vue.set(fieldMap, ix, {
        ...fieldMap[ix],
        ...partialValue,
      })
    }
  }

  @Mutation
  updateHaloBubble(payload: { value: HaloBubblesConfig; index: number }) {
    const { index, value } = payload
    this.appState.config.haloBubbles = replaceItemAtIndex(
      this.appState.config.haloBubbles,
      index,
      value,
    )
  }

  @Mutation
  addHaloConfig() {
    this.appState.config.haloBubbles.push({
      id: `halo-bubbles-id-${uuidv4()}`,
      name: 'Halo Bubbles',
      showHalo: true,
      radiusGap: 20, // twice the Hollow Bubbles borderWidth
      strokeOpacity: 0.85,
      strokeWidth: 10, // the same as Hollow Bubbles borderWidth
      strokeColor: '#ff0000',
      showPulse: false,
      pulsingDuration: 2,
      pulsingRadiusGap: 20,
      pulsingStrokeOpacity: 0.85,
      pulsingStrokeWidth: 65,
      pulsingStrokeColor: '#ff0000',
      filter: null,
    })
  }

  @Mutation
  removeHaloConfig(payload: { id: string }) {
    const { id } = payload
    this.appState.config.haloBubbles = this.appState.config.haloBubbles.filter(
      ({ id: haloId }) => haloId !== id,
    )
  }

  @Mutation
  updateFeedbackQuestion(config: FeedbackQuestionConfig) {
    this.appState.config.feedbackQuestions = replaceItemAtIndex(
      this.appState.config.feedbackQuestions,
      this.appState.config.feedbackQuestions.findIndex(
        (q: FeedbackQuestionConfig) => q.id === config.id,
      ),
      config,
    )
  }

  @Mutation
  addFeedbackQuestion() {
    this.appState.config.feedbackQuestions.push(createFeedbackConfig(uuidv4()))
  }

  @Mutation
  removeFeedbackQuestion(payload: { id: string }) {
    const { id } = payload
    this.appState.config.feedbackQuestions = this.appState.config.feedbackQuestions.filter(
      ({ id: questionId }) => questionId !== id,
    )
  }

  @Mutation
  addUserDetailsQuestion(config: UserDetailsQuestionConfig) {
    this.appState.config.userDetails.questions.push(config)
  }

  @Mutation
  updateUserDetailsQuestion(config: UserDetailsQuestionConfig) {
    this.appState.config.userDetails.questions = replaceItemAtIndex(
      this.appState.config.userDetails.questions,
      this.appState.config.userDetails.questions.findIndex(
        (q: UserDetailsQuestionConfig) => q.id === config.id,
      ),
      config,
    )
  }

  @Mutation
  removeUserDetailsQuestion(payload: { id: string }) {
    const { id } = payload
    this.appState.config.userDetails.questions = this.appState.config.userDetails.questions.filter(
      ({ id: questionId }) => questionId !== id,
    )
  }

  @Action({ rawError: true })
  updateConfig(payload: { configPath: (string | number)[]; value: unknown }) {
    // payload: { configPath: , value: }
    // This mutation takes an array of path strings to navigate the config object
    // eg ['treeDetails', 'Air/Cleaner home heating', 'order']
    // alternatively numbers can indicate the index in an array eg ['piechartItems', 0, 'color']
    // It then updates the final property with the new value, if that property exists
    const config = getObjectProp(payload.configPath.slice(0, -1), this.appState.config)
    this.setObjectProp({ configPath: payload.configPath, object: config, value: payload.value })
  }

  @Action({ rawError: true })
  updateAppConfig(payload: { prop: string; value: unknown }) {
    this.updateConfig({
      configPath: [APP_CONFIG_KEY, payload.prop],
      value: payload.value,
    })
  }

  @Action({ rawError: true })
  async saveConfig() {
    // Update all of the things
    try {
      const responses = await configService.updateVersionConfig(this.appState.config)

      // Check that each response has been successful
      if (responses.every((response) => response && response.includes('Success'))) {
        return 'success'
      }
    } catch {
      return 'failure'
    }

    return 'failure'
  }

  get getField() {
    return getField(this)
  }

  @Mutation
  updateField(options: { path: string; value: unknown }) {
    return updateField(this, options)
  }

  get ga4MeasurementIds() {
    return this.app.ga4MeasurementIds.filter((x) => x)
  }

  @Action({ rawError: true })
  async enableCustomerAnalytics() {
    if (this.ga4MeasurementIds?.length > 0) {
      const pageTrackerTemplate = () => ({
        page_title: typedStore.activeVisualisation.visualisationStore.app.pageTitle,
        page_path: typedStore.currentRoute.path,
        page_location: location.href,
      })
      const waitForSecondaryAppToLoad = async () => {
        if (typedStore.activeVisualisation.isSecondaryApp && !typedStore.secondary.app.appLoaded) {
          gtagOptOut()
          while (!typedStore.secondary.app.appLoaded) {
            await new Promise((resolve) => setTimeout(resolve, 250))
          }
          gtagOptIn()
          pageview(pageTrackerTemplate())
        }
      }

      gtagSetOptions({
        includes: this.ga4MeasurementIds.map((measurementId) => ({
          id: measurementId,
          params: {
            send_page_view: false,
            app_uuid: this.app.uuid,
            app_name: this.app.name,
            app_status: Object.entries(AppStatuses).find(
              ([, value]) => value === this.app.status,
            )?.[0],
          },
        })) as DomainConfig[],
        onReady: waitForSecondaryAppToLoad,
        onBeforeTrack: waitForSecondaryAppToLoad,
        pageTrackerTemplate,
      })

      await gtagBootstrap()
    }
  }
}

export const PrimaryAppModule = getModule(PrimaryApp)
