import { DEFAULT_ACTIVE_VISUALISATION_TYPE, PATH_ROOT, PATH_SUBROOT } from '@/constants/constants'
import { DEFAULT_NAV_BUTTONS_CONFIG, NAV_BUTTONS_CONFIG_KEY } from '@/constants/navButtons'
import {
  AppConfig,
  BubbleParamsConfig,
  BubblesClustersSortingConfig,
  BubblesLegendsConfig,
  ComparisonGraphSettingsConfig,
  Coordinate2D,
  CustomBubblesGroups,
  DownloadConfig,
  FaqConfig,
  FeedbackQuestionConfig,
  FeedbackRatingTypes,
  FieldCalculationV2Config,
  FieldMapItem,
  FilterByCategoryConfig,
  FilterDisplayTypes,
  HaloBubblesConfig,
  HollowBubbleConfig,
  HollowBubbleDisplays,
  LegendPositions,
  MapConfig,
  MonetaryFormattingConfig,
  NumericFormattingConfig,
  PopupConfig,
  SearchSettingsConfig,
  SortDirection,
  StoryKeys,
  StorySettingsConfig,
  SummaryTableConfig,
  SummaryTableOptions,
  SunburstConfig,
  UserDetailsConfig,
  VersionConfig,
  VisPositions,
  VisualisationConfig,
  XYBubblesConfig,
} from '@/types'

export const CONFIG_ENABLED_KEY = 'enabled'
export const GEOM_CONFIG_KEY = 'geomConfig'

export const FIELD_MAP_CONFIG_KEY = 'fieldMap'
export const MAP_POPUP_TOTAL_ITEMS_CONFIG_KEY = 'mapPopupTotalItems'
export const PIECHART_ITEMS_CONFIG_KEY = 'piechartItems'
export const POPUP_ITEMS_CONFIG_KEY = 'popupItems'
export const SHOW_MAP_CONFIG_KEY = 'showMap'
export const SPLASH_SCREEN_CONFIG_KEY = 'splashScreen' // premium feature
export const SUMMARY_ITEMS_CONFIG_KEY = 'summaryItems'
export const SELECT_VISUALISATION_TYPE_CONFIG_KEY = 'selectVisualisationType'

export const TOTAL_STRING_LABEL = 'Total'

export const MONETARY_UNIT = '$'

export const SEARCH_CONFIG_KEY = 'searchSettings'
export const DEFAULT_SEARCH_CONFIG: SearchSettingsConfig = {
  [CONFIG_ENABLED_KEY]: false,
  enableSearchByLocation: false,
  enableSearchForProjects: false,
  basemapType: 'default',
  filterDisplayType: FilterDisplayTypes.TRANSLUCENT,
  basemapCustom: {
    url: '',
    attribution: '',
  },
  basemapDefault: 'OpenStreetMap.Mapnik',
  lightness: 0.8,
  projectResultFields: [],
  searchName: 'deprecated',
  icon: null,
}

export const POPUP_CONFIG_KEY = 'popupConfig'
const DEFAULT_POPUP_CONFIG: PopupConfig = {}

export const DOWNLOAD_CONFIG_KEY = 'download'
export const DOWNLOAD_FIELDS_KEY = 'fields'
const DEFAULT_DOWNLOAD_CONFIG: DownloadConfig = {
  [CONFIG_ENABLED_KEY]: false,
  [DOWNLOAD_FIELDS_KEY]: [],
  buttonName: 'deprecated',
  buttonIcon: null,
}

// FAQ is a really common keyword, it is a config key, a feature key, a story panel key
export const FAQ_CONFIG_KEY = 'faq'
const DEFAULT_FAQ_CONFIG: FaqConfig = {
  [CONFIG_ENABLED_KEY]: false,
  text: '',
  buttonName: 'deprecated',
  buttonIcon: null,
  title: '',
}

export const COMPARISON_GRAPH_CONFIG_KEY = 'comparisonGraphSettings'
const DEFAULT_COMPARISON_GRAPH_SETTINGS: ComparisonGraphSettingsConfig = {
  show: false,
  showOnRoot: true,
  showOnSubroot: true,
  useStoryColour: false,
  rotate: false,
  multilineXLabels: true,
  showYAxis: true,
  showXAxis: true,
  showLegend: true,
  categories: [
    { title: 'Category A', fields: [''] },
    { title: 'Category B', fields: [''] },
  ],
  legenditems: [
    { label: 'Actual', color: '#cccccc' },
    // { label: 'Budgeted', color: '#000000' },
  ],
}

export const VISUALISATION_CONFIG_KEY = 'visualisation'
export const DEFAULT_VISUALISATION_CONFIG: VisualisationConfig = {
  type: DEFAULT_ACTIVE_VISUALISATION_TYPE,
  defaultVisualisationType: DEFAULT_ACTIVE_VISUALISATION_TYPE,
  activeVisualisationTypes: [DEFAULT_ACTIVE_VISUALISATION_TYPE],
}

export const SUNBURST_CONFIG_KEY = 'sunburst'
const DEFAULT_SUNBURST_CONFIG: SunburstConfig = {
  max: 3,
}

export const APP_CONFIG_KEY = 'appConfig'
export const DEFAULT_APP_NAME = 'custom app name'
export const DEFAULT_ORG_NAME = 'custom org name'
const DEFAULT_APP_CONFIG: AppConfig = {
  showPopupChart: false,
  showProjectCount: true,
  projectPlural: 'projects',
  projectSingular: 'project',
  totalStringLabel: TOTAL_STRING_LABEL,
  showPiechartSidebar: false,
  usePieChartStoryColour: false,
  appName: DEFAULT_APP_NAME,
  orgName: DEFAULT_ORG_NAME,
  customPageDescription: false,
  customPageTitle: false,
  showIcon: true,
  showLabel: true,
  showMobileIcon: true,
  showMobileLabel: true,
  customiseTabFontColour: false,
  logo: null,
  originalTabColours: false,
  graphStyle: 'pie',
  showSummaryTable: false,
  projectLabelField: '',
  hidePiechartOnRootSubroot: false,
  hidePiechartOnZeroNegative: false,
}

export const NUMERIC_FORMATTING_CONFIG_KEY = 'numericFormatting'
const DEFAULT_NUMERIC_FORMATTING_CONFIG: NumericFormattingConfig = {
  locale: 'en-nz',
  numDigits: 0,
}
export const MONETARY_FORMATTING_CONFIG_KEY = 'monetaryFormatting'
const DEFAULT_MONETARY_FORMATTING_CONFIG: MonetaryFormattingConfig = {
  currency: 'NZD',
  numDigits: 0,
}

export const BUBBLE_PARAMS_CONFIG_KEY = 'bubbleParams'
const DEFAULT_BUBBLE_PARAMS: BubbleParamsConfig = {
  bubbleMin: 0,
  bubbleMinAuto: true,
  bubbleMax: 100,
  spacing: 0,
  sideBubblePositionX: 1200,
  sideBubblePositionY: 0,
  showZeroValueBubbles: false,
  hideSideBubblesPercentage: 0,
}

export const SORT_BY_CLUSTER_SIZE_OPTION_VALUE = '_CLUSTER_SIZE_'
export const SORT_BY_CLUSTER_PATH_OPTION_VALUE = '_CLUSTER_PATH_'
export const BUBBLES_CLUSTERS_SORTING_CONFIG_KEY = 'bubblesClustersSorting'
const DEFAULT_BUBBLES_CLUSTERS_SORTING: BubblesClustersSortingConfig = {
  sortBy: SORT_BY_CLUSTER_SIZE_OPTION_VALUE,
  direction: SortDirection.DESCENDING,
}

export const MAP_CONFIG_KEY = 'mapConfig'
const DEFAULT_MAP_CONFIG: MapConfig = {
  markerIconName: 'map-marker',
  markerCustom: false,
  markerSize: 25,
  extent: {
    NWcoords: {
      lat: '-33',
      lng: '165',
    },
    SEcoords: {
      lat: '-49',
      lng: '180',
    },
  },
  legends: [
    {
      range: 0,
      colour: '#ffffff',
    },
    {
      range: 100000,
      colour: '#3af3b7',
    },
  ],
  style: {
    fill: true,
    color: '#ffffff',
    stroke: true,
    weight: '3',
    opacity: '1.0',
    dashArray: '10',
    dashOffset: '',
    fillOpacity: '0.8',
    highlightColor: '#000000',
  },
  maxScale: 11,
  minScale: 5,
  basemapType: 'default',
  basemapDefault: 'OpenStreetMap.Mapnik',
  basemapCustom: {
    url: '',
    attribution: '',
  },
}

export const STORY_SETTINGS_CONFIG_KEY = 'storySettings'
const DEFAULT_STORY_SETTINGS_CONFIG: StorySettingsConfig = {
  [StoryKeys.SIDEBAR_TOP]: {
    fontSize: 100,
    fontColor: '#FFFFFF',
    fontFamily: 'Arial',
    headingColor: '',
    linkColor: '',
  },
  [StoryKeys.SIDEBAR_BOT]: {
    fontSize: 100,
    fontColor: '#FFFFFF',
    fontFamily: 'Arial',
    headingColor: '',
    linkColor: '',
  },
  [StoryKeys.ABOVE_BUBBLES]: {
    fontSize: 100,
    fontColor: '#000000',
    fontFamily: 'Arial',
    headingColor: '',
    linkColor: '',
  },
  [StoryKeys.ABOVE_MAP]: {
    fontSize: 100,
    fontColor: '#000000',
    fontFamily: 'Arial',
    headingColor: '',
    linkColor: '',
  },
}

export const FEEDBACK_QUESTIONS_CONFIG_KEY = 'feedbackQuestions'
const DEFAULT_FEEDBACK_QUESTIONS_CONFIG: FeedbackQuestionConfig[] = []

export const USER_DETAILS_CONFIG_KEY = 'userDetails'
const DEFAULT_USER_DETAILS_CONFIG: UserDetailsConfig = {
  collectUserDetails: false,
  content: '',
  questions: [],
}

export const SUMMARY_TABLE_CONFIG_KEY = 'summaryTable' // premium feature
export const DEFAULT_SUMMARY_TABLE_OPTION = SummaryTableOptions.SUMMARY
const DEFAULT_SUMMARY_TABLE_CONFIG: SummaryTableConfig = {
  [PATH_ROOT]: SummaryTableOptions.CHILD,
  [PATH_SUBROOT]: SummaryTableOptions.CHILD,
  levelOptions: {},
  collapsable: false,
  collapseButtonText: 'Summary Table',
  displayAutoTotal: false,
  startCollapsed: false,
  displayHeaders: false,
  allowCustomSort: false,
  sortBy: 'value',
  sortDesc: true,
  sortTabOrder: true,
}

export const FIELD_CALCULATIONS_CONFIG_KEY = 'fieldCalculations'
export const FIELD_CALCULATIONS_NEW_CONFIG_KEY = 'fieldCalculationsV2Format'
const DEFAULT_FIELD_CALCULATIONS: FieldCalculationV2Config[] = []

export const DEFAULT_FIELD_MAP_CONFIG: FieldMapItem[] = []

export const HOLLOW_BUBBLES_CONFIG_KEY = 'hollowBubbles'
const DEFAULT_HOLLOW_BUBBLES_CONFIG: HollowBubbleConfig = {
  display: HollowBubbleDisplays.NEGATIVE,
  categoryField: null,
  categoryValue: null,
  borderWidth: 6,
  showLegend: false, // deprecated
  legendTitle: 'Title', // deprecated
  solidLabel: 'Solid Bubbles',
  hollowLabel: 'Hollow Bubbles',
  legendBubbleColor: '#000000',
  legendPosition: LegendPositions.BOTTOM_LEFT, // deprecated
}

export const FILTER_BY_CATEGORY_KEY = 'filterByCategory'
const DEFAULT_FILTER_BY_CATEGORY_CONFIG: FilterByCategoryConfig = {
  buttonName: 'deprecated',
  buttonIcon: null,
  enable: false,
  title: '',
  label: '',
  columns: null,
  position: LegendPositions.BOTTOM_LEFT,
}

// const CUSTOM_BUBBLES_GROUP_ID = `custom-bubbles-group-id-${uuidv4()}`
export const CUSTOM_BUBBLES_GROUPS_CONFIG_KEY = 'customBubblesGroups'
const DEFAULT_CUSTOM_BUBBLES_FILTERS: CustomBubblesGroups = {
  // [CUSTOM_BUBBLES_GROUP_ID]: {
  //   name: 'Regional Transport Above 64600',
  //   filters: [
  //     {
  //       category: 'programme',
  //       compare: null,
  //       operator: ComparisonEqualityOperators.STRICT_EQUAL,
  //       value: 'Regional Transport',
  //     },
  //     {
  //       category: 'grants',
  //       compare: null,
  //       operator: ComparisonEqualityOperators.GREATER_THAN_OR_EQUAL,
  //       value: 64600,
  //     },
  //   ],
  // },
}

export const HALO_BUBBLES_CONFIG_KEY = 'haloBubbles'
const DEFAULT_HALO_BUBBLES_CONFIG: HaloBubblesConfig[] = [
  // {
  //   id: `halo-bubbles-id-${uuidv4()}`,
  //   name: 'Halo Bubbles',
  //   showHalo: true,
  //   radiusGap: 20, // twice the Hollow Bubbles borderWidth
  //   strokeOpacity: 0.85,
  //   strokeWidth: 10, // the same as Hollow Bubbles borderWidth
  //   strokeColor: '#ff0000',
  //   showPulse: false,
  //   pulsingDuration: 2,
  //   pulsingRadiusGap: 20,
  //   pulsingStrokeOpacity: 0.85,
  //   pulsingStrokeWidth: 65,
  //   pulsingStrokeColor: '#ff0000',
  //   filter: CUSTOM_BUBBLES_GROUP_ID,
  // },
]

export const BUBBLES_LEGENDS_CONFIG_KEY = 'bubblesLegends'
export const DEFAULT_BUBBLES_LEGENDS_CONFIG: BubblesLegendsConfig = {
  showLegend: false,
  legendTitle: 'Title',
  legendPosition: LegendPositions.BOTTOM_LEFT,
}

export const XY_BUBBLES_AXIS_LABELS_KEY = 'axisLabels'
export const XY_BUBBLES_DIMENSIONS_MAPPINGS_KEY = 'dimensionsMappings'
export const XY_BUBBLES_Y_AXIS_TICKS_KEY = 'yAxisTicks'
export const XY_BUBBLES_Y_AXIS_TICK_CUSTOM_LABELS_KEY = 'customLabels'
export const XY_BUBBLES_CONFIG_KEY = 'xyBubblesConfig'
export const DEFAULT_XY_BUBBLES_CONFIG: XYBubblesConfig = {
  [XY_BUBBLES_AXIS_LABELS_KEY]: {
    x: {
      show: false,
    },
    y: {
      show: false,
    },
  },
  [XY_BUBBLES_DIMENSIONS_MAPPINGS_KEY]: [],
  [XY_BUBBLES_Y_AXIS_TICKS_KEY]: {
    showLabels: true,
    useDistinctValues: false,
    customLabels: [],
  },
}

export const DEFAULT_CONFIG: VersionConfig = {
  [APP_CONFIG_KEY]: DEFAULT_APP_CONFIG,
  [BUBBLE_PARAMS_CONFIG_KEY]: DEFAULT_BUBBLE_PARAMS,
  [BUBBLES_LEGENDS_CONFIG_KEY]: DEFAULT_BUBBLES_LEGENDS_CONFIG,
  [BUBBLES_CLUSTERS_SORTING_CONFIG_KEY]: DEFAULT_BUBBLES_CLUSTERS_SORTING,
  [COMPARISON_GRAPH_CONFIG_KEY]: DEFAULT_COMPARISON_GRAPH_SETTINGS,
  [CUSTOM_BUBBLES_GROUPS_CONFIG_KEY]: DEFAULT_CUSTOM_BUBBLES_FILTERS,
  [DOWNLOAD_CONFIG_KEY]: DEFAULT_DOWNLOAD_CONFIG,
  [FAQ_CONFIG_KEY]: DEFAULT_FAQ_CONFIG,
  [FEEDBACK_QUESTIONS_CONFIG_KEY]: DEFAULT_FEEDBACK_QUESTIONS_CONFIG,
  [USER_DETAILS_CONFIG_KEY]: DEFAULT_USER_DETAILS_CONFIG,
  [FIELD_CALCULATIONS_NEW_CONFIG_KEY]: DEFAULT_FIELD_CALCULATIONS,
  [FIELD_MAP_CONFIG_KEY]: DEFAULT_FIELD_MAP_CONFIG,
  [FILTER_BY_CATEGORY_KEY]: DEFAULT_FILTER_BY_CATEGORY_CONFIG,
  [HALO_BUBBLES_CONFIG_KEY]: DEFAULT_HALO_BUBBLES_CONFIG,
  [HOLLOW_BUBBLES_CONFIG_KEY]: DEFAULT_HOLLOW_BUBBLES_CONFIG,
  [MAP_CONFIG_KEY]: DEFAULT_MAP_CONFIG,
  [MAP_POPUP_TOTAL_ITEMS_CONFIG_KEY]: [],
  [MONETARY_FORMATTING_CONFIG_KEY]: DEFAULT_MONETARY_FORMATTING_CONFIG,
  [NAV_BUTTONS_CONFIG_KEY]: DEFAULT_NAV_BUTTONS_CONFIG,
  [NUMERIC_FORMATTING_CONFIG_KEY]: DEFAULT_NUMERIC_FORMATTING_CONFIG,
  [PIECHART_ITEMS_CONFIG_KEY]: [],
  [POPUP_CONFIG_KEY]: DEFAULT_POPUP_CONFIG,
  [SEARCH_CONFIG_KEY]: DEFAULT_SEARCH_CONFIG,
  [STORY_SETTINGS_CONFIG_KEY]: DEFAULT_STORY_SETTINGS_CONFIG,
  [SUMMARY_ITEMS_CONFIG_KEY]: [],
  [SUMMARY_TABLE_CONFIG_KEY]: DEFAULT_SUMMARY_TABLE_CONFIG,
  [SUNBURST_CONFIG_KEY]: DEFAULT_SUNBURST_CONFIG,
  [VISUALISATION_CONFIG_KEY]: DEFAULT_VISUALISATION_CONFIG,
  [XY_BUBBLES_CONFIG_KEY]: DEFAULT_XY_BUBBLES_CONFIG,
}

export function defaultBubblePosition(): Coordinate2D {
  return { x: 0, y: 0 }
}

export function defaultVisPositions(): VisPositions {
  return {
    default: defaultBubblePosition(),
  }
}

export const createFeedbackConfig = (id: string): FeedbackQuestionConfig => ({
  id,
  level: 0,
  allowComments: true,
  displayResults: true,
  ratingType: FeedbackRatingTypes.THUMBS,
  title: '',
  subtitle: '',
})
